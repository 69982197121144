<template>
  <div class="scheduleSetting">
    <a-modal
      v-model="visibleModal"
      :title="
        isEdit ? 'Form Edit Schedule Setting' : 'Form Create Schedule Setting'
      "
      @ok="actionModel"
      @cancel="hideModal"
      :width="1400"
      centered
    >
      <form-schedule-setting :data="dataPayload" @handle-change="changeScheduleSettingData"></form-schedule-setting>
    </a-modal>
    <div>
      <div class="d-lg-flex flex-column flex-md-row align-items-center mb-4">
        <div class="mb-3 mb-lg-0 mt-3 mt-lg-0 mx-0 mx-lg-0">
          <a-button
            :disabled="loadingRefresh"
            @click.prevent="fetchModel"
            size="large"
            class="w-100"
          >
            <a-icon type="sync" :spin="loadingRefresh" />
            REFRESH</a-button
          >
        </div>
        <div class="ml-auto text-center">
          <a-button
              @click.prevent="showModal()"
              type="primary"
              icon="plus"
              :block="isMobile"
              size="large"
              class="w-100"
              >CREATE</a-button
            >
        </div>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loadingTable"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="status" slot-scope="value">
        <a-tag :color="value === 'active' ? 'green' : 'red'">{{value}}</a-tag>
      </span>
      <div slot="actions" slot-scope="value, record">
        <a-button
          class="mr-3 text-warning border border-warning"
          size="large"
          @click.prevent="showModal(record)"
        >
          <a-icon type="edit" />Edit
        </a-button>
        <a-button
          class="text-danger border border-danger"
          size="large"
          @click.prevent="deleteData(record)"
        >
          <a-icon type="delete" />Delete
        </a-button>
      </div>
    </a-table>
  </div>
</template>
<script>
import moment from 'moment'
const FormScheduleSetting = () => import('@/components/app/Admin/Master/Form/ScheduleSetting')
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    align: 'center',
    // width: 200,
    scopedSlots: {
      // customRender: 'Name',
      filterIcon: 'filterIcon',
    },
  },
  // {
  //   title: 'Type',
  //   dataIndex: 'Type',
  //   align: 'center',
  //   // width: 150,
  //   // scopedSlots: {
  //   //   customRender: 'Type',
  //   // },
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    width: 300,
    ellipsis: true,
    scopedSlots: {
      customRender: 'status',
    },
  },
  // {
  //   title: 'Supervisor',
  //   dataIndex: 'Coach',
  //   align: 'center',
  //   // width: 400,
  //   // scopedSlots: {
  //   //   customRender: 'Coach',
  //   // },
  // },
  // {
  //   title: 'School Year',
  //   dataIndex: 'SchoolYear',
  //   align: 'center',
  //   // width: 200,
  //   // scopedSlots: { customRender: 'SchoolYear' },
  // },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    // width: 200,
    scopedSlots: { customRender: 'actions' },
  },
]
export default {
  components: { FormScheduleSetting },
  data() {
    return {
      columns,
      // loading
      loadingRefresh: false,
      loadingTable: false,
      // //
      // table
      dataTable: [],
      pagination: {},
      sortBy: 'status',
      order: 'ASC',
      page: 1,
      // //
      // modal
      isEdit: false,
      idEdit: null,
      visibleModal: false,
      // //
      // data
      dataPayload: {
        name: null,
        status: 'inactive',
        schedules: [],
      },
      // //
    }
  },
  methods: {
    validateAction(data) {
      const res = {
        isPassed: true,
        content: null,
      }
      const nullData = []
      for (const key in data) {
        if (!data[key]) {
          res.isPassed = false
          nullData.push(key)
        }
      }

      const str = nullData.join(', ')

      return {
        ...res,
        content: 'Please fill ' + str + ' correctly!',
      }
    },
    async actionModel() {
      const { name, status } = this.dataPayload
      const schedules = this.dataPayload.schedules.map(schedule => {
        return {
          jam_mulai: schedule.startAt.format('HH:mm:ss'),
          jam_selesai: schedule.endAt.format('HH:mm:ss'),
          tipe_jam: schedule.type === 'LESSON' ? 'kbm' : 'istirahat',
        }
      })
      const payload = {
        name,
        status,
        schedules,
      }
      if (!this.validateAction(payload).isPassed) {
        return this.$notification.error({
          message: 'Failed',
          description: this.validateAction(payload).content,
        })
      }
      const content = this.isEdit ? 'update' : 'create'
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to {content} this schedule setting?</div>
        ),
        onOk: async () => {
          try {
            if (this.isEdit) {
              await this.$store.dispatch('admin/UPDATE_MODEL', { newData: payload, id: this.idEdit })
            } else {
              await this.$store.dispatch('admin/CREATE_MODEL', payload)
            }
            this.fetchModel()
            this.$notification.success({
              message: 'Success',
              description: `Schedule setting has been ${content}d`,
            })
            this.hideModal()
          } catch (err) {
            console.log(err)
            this.$notification.error({
              message: 'Failed',
              description: `Schedule setting has not been ${content}d`,
            })
            this.fetchModel()
          }
        },
        onCancel: () => {
          this.loadingDeleteProgram = false
        },
        centered: true,
        icon: 'warning',
        okType: this.isEdit ? 'primary' : 'danger',
        okText: this.isEdit ? 'Edit' : 'Create',
        cancelText: 'Cancel',
      })
    },
    changeScheduleSettingData({ value, key }) {
      let data = value
      if (key === 'schedules') {
        data = data.sort((a, b) => a.startAt - b.startAt)
      }
      this.dataPayload[key] = data
    },
    async fetchModel() {
      this.loadingTable = true
      const { page, sortBy, order } = this
      const { data, total } = await this.$store.dispatch('admin/FETCH_MODEL_MASTER', { page, sortBy, order })
      this.dataTable = data.map(dat => {
        return {
          ...dat,
          key: dat.id,
          schedules: dat.model_jadwals,
        }
      })
      this.schoolYearLoadingTable = false
      const pagination = { ...this.pagination }
      pagination.total = total
      this.pagination = pagination
      this.loadingTable = false
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      this.sortBy = sorter.columnKey === 'Name' ? 'nama' : sorter.columnKey
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      this.fetchModel()
    },
    showModal(data) {
      this.visibleModal = true
      if (data) {
        this.isEdit = true
        this.idEdit = data.id
        let key = 0
        let schedules = data.schedules.map(schedule => {
          key++
          const startAt = moment(schedule.jam_mulai, 'HH:mm')
          const endAt = moment(schedule.jam_selesai, 'HH:mm')
          const duration = endAt.diff(startAt, 'minutes')
          return {
            key,
            startAt,
            endAt,
            timeStr: `${startAt.format('HH:mm')} - ${endAt.format('HH:mm')}`,
            type: schedule.tipe_jam === 'istirahat' ? 'BREAK' : 'LESSON',
            duration,
          }
        })
        schedules = schedules.sort((a, b) => a.startAt - b.startAt)
        this.dataPayload = {
          name: data.name,
          status: data.status,
          schedules,
        }
      }
    },
    hideModal() {
      this.visibleModal = false
      this.isEdit = false
      this.idEdit = null
      this.dataPayload = {
        name: null,
        status: 'inactive',
        schedules: [],
      }
    },
    async deleteData(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete this schedule setting?</div>
        ),
        onOk: async () => {
          try {
            await this.$store.dispatch('admin/DELETE_MODEL', {
              id: data.id,
            })
            this.fetchModel()
            this.$notification.success({
              message: 'Success',
              description:
                'Schedule setting has been deleted',
            })
          } catch (err) {
            console.log(err)
            this.$notification.error({
              message: 'Failed',
              description: 'Schedule setting has not been deleted',
            })
            this.fetchModel()
          }
        },
        onCancel: () => {
          this.loadingDeleteProgram = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
  },
  created() {
    this.fetchModel()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style>

</style>
